import * as React from 'react'
import { Box, Heading, Text } from '@chakra-ui/react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import { NavBar } from '../components/navbar'
import { CtaSimple } from '../components/ctaSimple'
import fractalBg from '../assets/svg/info-illustrations/jagged-bg.png'

const PrivacyPage = () => (
  <Layout>
    <Seo title="Terms of Use" />
    <Box
      bgImage={fractalBg}
      bgPosition="center"
      bgRepeat="no-repeat"
      bgSize="cover"
    >
      <NavBar />
      <CtaSimple
        title="Terms of Use"
        helperText="Last Updated: 10/10/2022"
        titleSize="3xl"
        pageHeader
      />
    </Box>
    <Box
      as="section"
      maxW={{
        base: 'xl',
        md: '7xl',
      }}
      mx="auto"
      px={{
        base: 4,
        md: 8,
      }}
      py={{
        base: 10,
        md: 24,
      }}
    >
      <Heading as="h2" size="xl" mb={12}>
      Terms and Conditions of Use for Synapse Studios, LLC dba CarbonQA ("CarbonQA", “company”, “we”, “us”)
      </Heading>
      <Text>
        These Terms and Conditions of Use govern your use of the website located at https://carbonqa.com and your relationship with CarbonQA and all individuals and companies associated with this website. 
        Use of our website constitutes your acceptance of the Terms and Conditions. 
        If you do not agree to these Terms and Conditions, do not use our site. 
        We may occasionally change the Terms of Use without notice to you by posting such changes on our site. 
        By using our site following any modifications to these terms, you agree to be bound by any modifications.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Copyrights
      </Heading>
      <Text>
        All content and functionality on the site, including text, graphics, logos, icons, and images and the selection and arrangement thereof, is the exclusive property of Synapse Studios, LLC or its licensors and is protected by U.S. and international copyright laws. 
        All rights not expressly granted are reserved.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Trademarks
      </Heading>
      <Text>
        The trademarks, service marks, designs, and logos (Trademarks) displayed on our site are the registered and unregistered Trademarks of Synapse Studios, LLC and its licensors. 
        You agree that you will not refer to or attribute any information to Synapse Studios, LLC or its licensors in any public medium (e.g., press release, websites) for advertising or promotion purposes, or for the purpose of informing or influencing any third party and that you will not use or reproduce any Trademark of, or imply any endorsement by or relationship with, Synapse Studios, LLC or its licensors.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Use of Site Content
      </Heading>
      <Text>
        Synapse Studios, LLC grants you the ability to access and download, display and print one copy of the content and functionality displayed on our website as long as you do not modify the content in any way and that you retain all copyright and other proprietary notices. 
        You may not otherwise reproduce, modify, distribute, transmit, post, or disclose our website content without our written consent.
      </Text>

      <Heading as="h3" size="xl" mt={12}>
        Disclaimer
      </Heading>
      <Text>
        Our site's content and functionality is provided with the understanding that Synapse Studios, LLC is not rendering professional advice and services to you. 
        All content and functionality is provided "as is" without warranty of any kind. 
        Synapse Studios, LLC has no liability or responsibility for any information published on linked websites, contained in any of our published content or provided by third parties. 
        Synapse Studios, LLC will not be liable for any indirect, incidental, consequential, or punitive damages or for lost revenues or profits regardless of the theory of liability.
      </Text>
    </Box>
  </Layout>
)

export default PrivacyPage
